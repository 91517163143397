import React from 'react';
import { styled } from '@compiled/react';

import { N20 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import type { RendererContentStatePlaceholderProps } from './types';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RendererContentStateLoadingPlaceholder = styled.li({
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer',
	minHeight: '32px',
	backgroundColor: token('color.background.neutral', N20),
	borderRadius: '3px',
	paddingRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentStateNamePlaceholderWrapper = styled.div({
	fontWeight: 500,
	visibility: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentStateColorPlaceholder = styled.div({
	borderRadius: '12px',
	height: '12px',
	width: '12px',
	margin: `0 ${token('space.100', '8px')}`,
	backgroundColor: token('color.background.neutral', N20),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledBorder = styled.div({
	borderLeft: `2px solid ${token('color.border.accent.gray', 'rgba(107, 119, 140, 0.3)')}`,
	display: 'inline-block',
	width: '1px',
	height: '16px',
	margin: `0 ${token('space.050', '4px')} 0 10px`,
	alignSelf: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FlexWrapper = styled.div({
	display: 'flex',
	marginLeft: token('space.075', '6px'),
	marginRight: token('space.075', '6px'),
});

export const RendererContentStatePlaceholder = ({
	contentState,
	isPagePreview,
}: RendererContentStatePlaceholderProps) => {
	return (
		<FlexWrapper>
			<RendererContentStateLoadingPlaceholder data-testid="renderer-content-state-placeholder">
				<ContentStateColorPlaceholder />
				<ContentStateNamePlaceholderWrapper>
					{contentState?.name}
				</ContentStateNamePlaceholderWrapper>
			</RendererContentStateLoadingPlaceholder>
			{!isPagePreview && <StyledBorder data-testid="placeholder-vertical-line" />}
		</FlexWrapper>
	);
};
